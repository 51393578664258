//import { Table, TableHead, TableHeaderCell, TableBody, TableRow, TableCell } from "@tremor/react";
import {
    Card,
    Table,
    TableHead,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    Text,
    Title,
    Badge,
  } from "@tremor/react";
export function DataTable({data}){

    console.log("DataTableData", data);

    return (

        <Card>
    <Title></Title>
    <Table className="mt-5">
      <TableHead>
        <TableRow>
          <TableHeaderCell>Date</TableHeaderCell>
          <TableHeaderCell>Zone</TableHeaderCell>
          <TableHeaderCell>Resident</TableHeaderCell>
          <TableHeaderCell>Call Point</TableHeaderCell>
          <TableHeaderCell>Call Type</TableHeaderCell>
          <TableHeaderCell>Start Time</TableHeaderCell>
          <TableHeaderCell>End Time</TableHeaderCell>
          <TableHeaderCell>Duration</TableHeaderCell>
          <TableHeaderCell>Carer</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <TableRow key={item.start}>
            <TableCell>{item.start}</TableCell>
            <TableCell><Text>{item.zone}</Text></TableCell>
            <TableCell><Text>{item.resident}</Text></TableCell>
            <TableCell><Text>{item.panelRef}</Text></TableCell>
            <TableCell><Text>{item.callType}</Text></TableCell>
            <TableCell><Text>{item.startFullDate}</Text></TableCell>
            <TableCell><Text>{item.end}</Text></TableCell>
            <TableCell><Text>{item.duration}</Text></TableCell>
            <TableCell><Text>{item.carer}</Text></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Card>
    
    );

}
