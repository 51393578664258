import { BarList, Card, Title, Bold, Flex, Text } from "@tremor/react";
import { apiPost } from "../../data/apidata";
import { useState, useEffect } from "react";
import IMG_HYDRATION from "../../images/care_clicks/nutrition_hydration.png";
import IMG_CONTINENCE from "../../images/care_clicks/continence.png";
import IMG_MOVING from "../../images/care_clicks/moving.png";
import IMG_PERSONAL from "../../images/care_clicks/personal_care.png";
import IMG_SLEEP from "../../images/care_clicks/sleep_rest.png";
import IMG_SOCIAL from "../../images/care_clicks/social.png";


export default function CareDeliveredByType(){

  const [datas, setData] = useState([]);
  const [error, setError] = useState("");
  
  useEffect(()=>{ apiPost("dashboard/chart/caredeliveredbytype", {}, setData, setError); },[]);

  const data = [
    {
      name: "Nutrition",
      value: 9,
//      href: "https://twitter.com/tremorlabs",
icon: function () {
  return (
    <img src={IMG_HYDRATION} className="reports_careclicks" />
  );
},
    },
    {
      name: "Continence",
      value: 351,
   //   href: "https://google.com",
   icon: function () {
    return (
      <img src={IMG_CONTINENCE} className="reports_careclicks" />
    );
  },
    },
    {
      name: "Hydration",
      value: 50,
      icon: function () {
        return (
          <img src={IMG_HYDRATION} className="reports_careclicks" />
        );
      },
    
    },
    {
      name: "Moving",
      value: 191,
      icon: function () {
        return (
          <img src={IMG_MOVING} className="reports_careclicks" />
        );
      },
    },
    {
      name: "Personal",
      value: 91,
 //     href: "https://www.youtube.com/@tremorlabs3079",
            icon: function () {
        return (
          <img src={IMG_PERSONAL} className="reports_careclicks" />
        );
      },
     
    },
    {
      name: "Sleep",
      value: 91,
 //     href: "https://www.youtube.com/@tremorlabs3079",
            icon: function () {
        return (
          <img src={IMG_SLEEP} className="reports_careclicks" />
        );
      },
     
    },
    {
      name: "Social",
      value: 91,
 //     href: "https://www.youtube.com/@tremorlabs3079",
            icon: function () {
        return (
          <img src={IMG_SOCIAL} className="reports_careclicks" />
        );
      },
     
    }
  ];
  
  return (
    <Card className="max-w-lg">
      <Title>Care Delivered By Type</Title>
      <Flex className="mt-4">
        <Text>
          <Bold>Source</Bold>
        </Text>
        <Text>
          <Bold>Calls</Bold>
        </Text>
      </Flex>
      <BarList data={data} className="mt-2" />
    </Card>
  );
}