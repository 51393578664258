import { useContext } from 'react';
import './App.css';
import DashboardExample from './Pages/Dashboard';
import Live from './Pages/Live';
import UserMenu from './components/UserMenu';
import IMG_LOGO from './images/logo.png';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import React from 'react';
import Move from './Pages/Move';
import TextViewPage from './Pages/TextView';
import People from './Pages/People';
import Place from './Pages/Place';
import ShowRoom from './Pages/ShowRoom';
import { login } from './data/firebase';
import VayyarMonitor from './Pages/VayyarMonitor';
import Settings from './Pages/Settings';
import LoginScreen from './Pages/Login/Login';
import { useAuth } from './context/AuthProvider';
import VirtualEnvironment from './Pages/VirtualEnvironment/VirtualEnvironment';
import FloorPlan from './components/FloorPlan/FloorPlan';

function App() {

  const auth = useAuth();
  
  const handleLogout = () => {
    auth.logout();
  };

  //if (!auth.isLoggedIn()) return <LoginScreen />;

  login("andrewchapman@arquella.co.uk", "arquella");

  return (
    <div 
    //className="App"
    >
    <HeaderView />
    <br />
    <br />
    <BrowserRouter>
      <Routes>
        <React.Fragment>

          <Route path='/' element={<Live />} />
          <Route path="/move" element={<MoveView />} />
          <Route path="/sense" element={<SenseView />} />
          <Route path="/care" element={<CareView />} />
          <Route path="/text" element={<TextView />} />
          <Route path="/dash" element={<DashView />} />
          <Route path="/live" element={<Live />} />
          {/*<Route path="/people" element={<People />} />*/}
          <Route path="/place" element={<Place />} />
          <Route path="/showroom" element={<ShowRoom />} />
          <Route path="/vayyarmonitor" element={<VayyarMonitor />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/virtual" element={<VirtualEnvironment />} />
          <Route path="/floorplan" element={<FloorPlan />} />
        </React.Fragment>
       
        {
        /*
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        */
        }
      </Routes>
    </BrowserRouter>

    </div>
  );
}

const Signin=()=>{
  return (<>You are not signed in.</>);
}

const HeaderView=()=>{ 
return (<>
    <header className="App-header">
      <img src={IMG_LOGO} className='header_logo' />
      <UserMenu />
    </header>
</>); }

const DashView=()=>{ return (<>
  <DashboardExample />
</>); }


const MoveView=()=>{ return (<>
  <Move />
</>); }

const SenseView=()=>{ return (<>
  
  </>); 
}

const CareView=()=>{ return (<>
  
  </>); 
}

const TextView=()=>{ return (
  <>
      
      <HeaderView />
      <TextViewPage />

  </>
  ); 
}

export default App;
