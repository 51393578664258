import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import AuthProvider from './context/AuthProvider';

const appRoot = "https://api.arquel.la/v2";
//const appRoot = "http://localhost:8180";
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = process.env.REACT_APP_ROOT;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = "https://dev.arquella.care";
axios.defaults.headers.post['Access-Control-Allow-Origin'] = appRoot;
axios.defaults.headers.post['Accepts'] = 'application/json; charset=UTF-8; text/plain; */*';
//axios.defaults.baseURL = process.env.REACT_APP_API_ROOT;
axios.defaults.baseURL = appRoot;

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
      // Registration successful
      console.log('Service worker registered:', registration);
    })
    .catch(error => {
      // Registration failed
      console.error('Service worker registration failed:', error);
    });
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
