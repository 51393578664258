import { Card, Title } from "@tremor/react";
import { useState, useEffect } from "react";
import { Accordion, AccordionHeader, AccordionBody, AccordionList } from "@tremor/react";
import { GetCallTypeImage } from "../GetImage";
import { getOnValue } from "../../data/firebase";
import { lCase } from "../../functions/string";
import CallTimer from "../CallTimer";

export default function LiveDashboardLiveCalls({sitesList}){

  const [calls, setCalls] = useState([]);
  const [masterCalls, setMasterCalls] = useState([]);
  
  useEffect(()=>{
    if (masterCalls===null) {
      setCalls([]);
      return;
    }
    const tmpsCalls = [];
    Object.keys(masterCalls).map((k)=>{
      tmpsCalls.push(masterCalls[k]);
    });
    setCalls(tmpsCalls);
  }, [masterCalls])

  function onError(error){
    console.log("error", error);
  }

  function setupSiteListeners(){
    if ((!sitesList)||(sitesList.length===0)) return;
    sitesList.forEach(site => {
      if (site==="") return;
      getOnValue(site + "PhoneLiveCalls", setMasterCalls, onError);
    });
  }

  useEffect(()=>setupSiteListeners(), [sitesList]);

  return(

  <Card 
  //className="max-w-sm"
  >
    <Title className="upper_case">Live Calls</Title>
    <br />
    <div className="dashboard_frame">
      <AccordionList className="max-w-md mx-auto">
        {calls.map((c)=><CallCard data={c} />)}
      </AccordionList>
    </div>
  </Card>
)};



const CallCard=({data})=>{
  
  

  return (
    <Accordion className={"bg_" + lCase(data.callType)}>

      <AccordionHeader>
        <CallHeader data={data} />
      </AccordionHeader>

      <AccordionBody>
        <CallBody data={data} />
      </AccordionBody>

    </Accordion>
  );
}


const CallHeader=({data})=>{
  console.log("callheaderlive", {data});
  return (
    <div className="live_call_header_container">
      <GetCallTypeImage imgString={lCase(data.callType)} />
      <div>
        <div>{data.room}</div>
        <div><small>{data.zone}</small></div>
      </div>
      <div><CallTimer startTime={data.start} /></div>
    </div>
  );
}


const CallBody=({data})=>{

  return (
    <div>
      <CallBodyEntry field="Sense Type" value={data.AccessoryType} />
      <CallBodyEntry field="Start" value={data.startFullDate} />
      <CallBodyEntry field="Carer" value={data.carer} />
      {/*
        <CallBodyEntry field="panelRef" value={data.panelRef} />
        <CallBodyEntry field="unitId" value={data.unitId} />
        <CallBodyEntry field="start" value={data.start} />
        <CallBodyEntry field="journeyRef" value={data.journeyRef} />
        <CallBodyEntry field="txCode" value={data.txCode} />
      */}
    </div>
  );
}

const CallBodyEntry=({field, value})=>{
  
  if (value=="") return<></>;
  
  return(      
    <div className="call_body_field_header">
      <div>{field}</div>
      <div>{value}</div>
    </div>
  );
}

