import { useEffect, useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/solid";

import {
  Card,
  Grid,
  Title,
  Text,
  Tab,
  TabList,
  TabGroup,
  TabPanel,
  TabPanels,
  BadgeDelta,
  DeltaType,
  Flex,
  Metric,
  ProgressBar,
  AreaChart,
  Color,
  Icon,
  MultiSelect,
  MultiSelectItem,
  Select,
  SelectItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "@tremor/react";
import axios from "axios";

export default function ChartCard({data}){
    
    const Kpis = {
        Sales: "Sales",
        Profit: "Profit",
        Customers: "Customers",
      };
      
    const kpiList = [Kpis.Sales, Kpis.Profit, Kpis.Customers];  
    
    const [selectedIndex, setSelectedIndex] = useState(0);
    const selectedKpi = kpiList[selectedIndex];

    const areaChartArgs = {
        className: "mt-5 h-72",
        data: data,
        index: "date",
        categories: [selectedKpi],
        colors: ["blue"],
        showLegend: false,
    //    valueFormatter: formatters[selectedKpi],
        yAxisWidth: 56,
      };



    return (
        <Card>
        <>
          <div className="md:flex justify-between">
            <div>
              <Flex className="space-x-0.5" justifyContent="start" alignItems="center">
                <Title>Calls</Title>
                <Icon
                  icon={InformationCircleIcon}
                  variant="simple"
                  tooltip="Shows daily increase or decrease of particular domain"
                />
              </Flex>
              <Text> Daily change per domain </Text>
            </div>
            <div>
              <TabGroup index={selectedIndex} onIndexChange={setSelectedIndex}>
                <TabList color="gray" variant="solid">
                  <Tab>Emergency</Tab>
                  <Tab>Attendance</Tab>
                  <Tab>Call</Tab>
                  <Tab>Accessory</Tab>
                </TabList>
              </TabGroup>
            </div>
          </div>
          {/* web */}
          <div className="mt-8 hidden sm:block">
            <AreaChart {...areaChartArgs} />
          </div>
          {/* mobile */}
          <div className="mt-8 sm:hidden">
            <AreaChart
              {...areaChartArgs}
              startEndOnly={true}
              showGradient={false}
              showYAxis={false}
            />
          </div>
        </>
      </Card>

    );
}