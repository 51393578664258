import { useEffect, useState } from 'react';
import './Phone.css';
import IMG_LOGO from '../../images/logo_white_background.jpg';
import { lCase } from '../../functions/string';
import {GetCallTypeImagePanel} from "../../components/GetImage";
import CallTimer from '../../components/CallTimer';

export const Phone=({calls})=>{

    return (
        <div className='virtualPhone'>
            <div className='virtualPhoneHeader'>
                adc@arquella.co.uk
            </div>
            <div className='virtualPhoneMain'>
                {
                ((calls===undefined)||(calls.length===0))
                ? <LogoScreen />
                : <MainPhoneBody calls={calls} />
                }
            </div>
            <div className='virtualPhoneFooter'>Footer</div>
        </div>
    );
}


const LogoScreen=()=>{
    return <div><br/><img src={IMG_LOGO} /></div>;
}
    
const MainPhoneBody=({calls})=>{
    return <div>{calls.map((c,i)=><DisplayCall index={i} callDetails={c} />)}</div>
}


const DisplayCall=({index, callDetails})=>{
    return (
        <div className={`bg_${lCase(callDetails.callType)} phone_call_container`}>
            <div><GetCallTypeImagePanel imgString={lCase(callDetails.callType)} /></div>
            <div className='phone_content'>
                <div className='phone_calltype'>{callDetails.callType}</div>
                <div className='phone_call_body'>
                    <div>
                        <div className=''>{callDetails.room}</div>
                        <div className=''>{callDetails.zone}</div>
                    </div>
                    <div className='phone_timer'><CallTimer startTime={callDetails.start} /></div>
                </div>     
            </div>

            
{/*
            <div className='panelCallFooter'>
                <div className='panel_call_body_content_txcode'>{callDetails.txCode}</div>
                <div className='panel_call_body_content_timer'>{callDetails.start}</div>
            </div>
*/}
        
        </div>
    );
}