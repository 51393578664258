import React, { useEffect } from 'react';

const Move=()=>{

    //const site_guid = "018290c0-4a7b-9f03-18bb-64cba6e030a5";     //arquella office
    //const site_guid = "018586db-51a1-61b1-9b05-2ba58ffac7a3";   //weir
    //const site_guid = '016fd235-8e10-1486-23f6-5e6cc6f4827b'; //iiwari
    const site_guid = '018290c0-4a7b-9f03-18bb-64cba6e030a5'; //iiwari
    
    
    useEffect(()=>{
        return;
        setTimeout(()=>{
            let frame = document.getElementById("iiwariframe");
        }, 5000);
    },[]);
    
    return(
        <div width="100%">
            <iframe
                id='iiwariframe' 
                src={"https://app.iiwari.cloud/arquella?site=" + site_guid + "&theme=iframe"}
                //sandbox="allow-top-navigation"
                style={{
                    marginLeft: "2.5%",
                    marginTop: "2.5%",
                    alignSelf: "center",
                    height: "80vh",
                    //margin: "3vh",
                    //width: "95%",
                    width: "1200px",
    //                    minHeight: "",
                    marginRight:"10px", 
                    marginTop:"10px",
                    //border:"none",
                }}
                >
            </iframe>
            {/*<LocationEventRunner></LocationEventRunner>*/}
            <div id="newtest"></div>
        </div>
    );

}

export default Move;