import './Modal.css';

export const AQModal=(props)=>{

    if (props.show!==true) return <></>;

    return(
        <div id="aqDashModal" class="aqDashModal">
            <div class="aqDashModal-content">{props.children}</div>
            <span class="aqDashModal-close"
                onClick={()=>props.onClose()}
            >&times;</span>
        </div>
    );
}