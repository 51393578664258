import './VirtualEnvironment.css';
import { Phone } from './Phone';
import { Panel } from './Panel';
import { useEffect, useState } from 'react';
import { getOnValue } from '../../data/firebase';
import {apiPost} from '../../data/apidata';
import IMG_ROOMUNIT from '../../images/Room unit.jpg';
import FloorPlan from '../../components/FloorPlan/FloorPlan';
import {
    ProgressBar,
    Card,
    Flex,
    Text,
    Metric,
    TabList,
    Tab,
    TabGroup,
    TabPanels,
    TabPanel,
  } from "@tremor/react";
  import { UserGroupIcon, UserIcon } from "@heroicons/react/solid";
import SenseController from './SenseController';

const VirtualEnvironment=()=>{

    const [masterCalls, setMasterCalls] = useState([]);
    const [liveCalls, setLiveCalls] = useState([]);
    const [errors, setErrors] = useState([]);
    const [carer, setCarer] = useState("Carer 1");

    useEffect(()=>{
        console.log("MASTERCALLS", masterCalls);
        try { setLiveCalls(Object.values(masterCalls)); }
            catch(e){ setLiveCalls([]); }
    }, [masterCalls]);
    
    useEffect(()=>{
        getOnValue("arquellacarePhoneLiveCalls", setMasterCalls, setErrors);
    },[])

    function raiseCall(room, callCode, strCarer){
        console.log("raising call");
        console.log("room", room);
        console.log("code", callCode);
        apiPost("/call/live/raise",
            {
                "userID":"",
                "siteID" : "arquellacare",
                "loginName" : "arquellacare@arquella.co.uk",
                "callType": callCode,
                "roomCode": room,
                "title": "",
                "comments": "",
                "carer": strCarer,
                "virtualCall": true
            },
            (d)=>{console.log(d)},
            (d)=>{console.log(d)}
        );

    }

    function floorplanCallBack(staffLocationRoom, lastCall){
        console.log("staff", staffLocationRoom);
        if (staffLocationRoom===""){
            if (lastCall!=="") raiseCall(lastCall, "3", carer);     //clear last call
        }
        else {
            raiseCall(staffLocationRoom, "5", carer);                       //raise call
        }
    }

    const RoomUnit=()=>{
        return(
            <div style={{"display":"flex", "flex-direction":"row", "justifyContent": "space-around"}}>
                    <img className='room_unit' src={IMG_ROOMUNIT} />
                    <div style={{"display":"flex", "flex-direction":"column", "justifyContent": "space-between"}}>
                        <button onClick={()=>{ raiseCall("001", "2", "") }}>Raise Call</button>
                        <button onClick={()=>{ raiseCall("001", "5", "") }}>Attend</button>
                        <button onClick={()=>{ raiseCall("001", "1", "") }}>Emergency</button>
                        <button onClick={()=>{ raiseCall("001", "4", "") }}>Assistance</button>
                        <button onClick={()=>{ raiseCall("001", "6", "") }}>Accessory</button>
                        <button onClick={()=>{ raiseCall("001", "3", "") }}>Clear</button>
                    </div>
                </div>
        );
    }

    return(
        <div style={{"display":"flex"}}>
            <div style={{"display":"flex", "flex-direction":"column", "justifyContent": "space-between", "padding": "10px"}}>
                <div class="phone landscape">
                    <Panel calls={liveCalls} carer={carer} />
                </div>
                <div class="phone portrait">
                    <Phone calls={liveCalls} carer={carer}  />
                </div>
            </div>

            <div style={{"display":"flex", "flex-direction":"column", "padding": "10px", "width":"100%"}}>
                <Card>
                    Carer: &nbsp;&nbsp;&nbsp;&nbsp; 
                    <input 
                        onChange={(e)=>{
                            console.log(e.target.value);
                            setCarer(e.target.value)
                        }}
                        value={carer}
                    />
                </Card>
        
            <TabGroup>
                <TabList className="mt-8">
                    <Tab icon={UserGroupIcon}>Call</Tab>
                    <Tab icon={UserIcon}>Sense</Tab>
                    <Tab icon={UserIcon}>Move</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <RoomUnit />
                    </TabPanel>
                    <TabPanel>
                        <SenseController />
                    </TabPanel>
                    <TabPanel>
                        <FloorPlan carer={carer} staffMovementCallBack={floorplanCallBack} callsList={liveCalls} />            
                    </TabPanel>
                </TabPanels>
            </TabGroup>

            </div>            

            

        </div>
    );
}

export default VirtualEnvironment;