import { useEffect, useState } from 'react';
import './Panel.css';
import IMG_LOGO from '../../images/logo_white_background.jpg';
import { lCase } from '../../functions/string';
import {GetCallTypeImagePanel} from "../../components/GetImage";
import CallTimer from '../../components/CallTimer';

export const Panel=({calls})=>{
    return (
        <div className='virtualPanel'>
            <div className='virtualPanelHeader'>
                adc@arquella.co.uk
            </div>
            <div className='virtualPanelMain'>
                {
                (calls.length>0)
                ? <MainPanelBody calls={calls} />
                : <LogoScreen />
                }
            </div>
            <div className='virtualPanelFooter'>Footer</div>
        </div>
    );
}

const LogoScreen=()=>{
return <div><br/><img src={IMG_LOGO} /></div>;
}


const MainPanelBody=({calls})=>{
    /*
        AccessoryType
        aqRef
        beaconId
        callType
        carer
        duration
        end
        journeyRef
        locTx
        panelRef
        room
        start
        startFullDate
        txCode
        unitId
        zone
    */
    return (
    <div>
        {calls.map((c,i)=><DisplayCall index={i} callDetails={c} />)}
    </div>
    ); 
}

const DisplayCall=({index, callDetails})=>{
    return (
        <div className={`bg_${lCase(callDetails.callType)}`}>
            <div className='panelCallHeader'>
                <div>
                    <div className='panel_call_body_content_calltype'>{callDetails.callType}</div>
                    <div className='panel_call_body_content_accessorytype'>{callDetails.AccessoryType}</div>
                </div>
            </div>
            <div className='panelCallBody'>
                <div><GetCallTypeImagePanel imgString={lCase(callDetails.callType)} /></div>
                <div className='panelCallBodyContent'>
                    <div className='panel_call_body_content_room'>{callDetails.room}</div>
                    <div className='panel_call_body_content_zone'>{callDetails.zone}</div>    
                </div>
            </div>
            <div className='panelCallFooter'>
                <div className='panel_call_body_content_txcode'>{callDetails.txCode}</div>
                <div className='panel_call_body_content_timer'><CallTimer startTime={callDetails.start} /></div>
            </div>
            {//
            }
        
        </div>
    );
}