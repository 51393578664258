import axios from "axios";

export function apiPost(path, data, onData, onError){
    axios.post(path, data)
    .then((data)=>{
        console.log("dddddd", data);
        onData(data.data);
    })
    .catch((err)=>onError(err));
}

