import { Card, Title, BarChart, Subtitle } from "@tremor/react";

const chartdata2 = [
  {
    topic: "Monday",
    "Ground Floor": 320,
    "First Floor": 103,
    "Second Floor": 136,
  },
  {
    topic: "Tuesday",
    "Ground Floor": 120,
    "First Floor": 233,
    "Second Floor": 53,
  },
];

const dataFormatter = (number) => {
  return "$ " + Intl.NumberFormat("us").format(number).toString();
};

export default function AverageResponseTimeByZone() {
  return(
  <Card>
    <Title>Average Response Time By Zone (minutes)</Title>
    <BarChart
      className="mt-6"
      data={chartdata2}
      stack={true}
      index="name"
      categories={["Ground Floor", "First Floor", "Second Floor"]}
      colors={["blue", "teal", "amber", "rose", "indigo", "emerald"]}
      //valueFormatter={dataFormatter}
      yAxisWidth={48}
    />
  </Card>
  );
};