import React, { createContext, useContext, useState } from 'react';
import { apiPost } from '../data/apidata';
// Create the AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider component
const AuthProvider = ({ children }) => {
  console.log(new Date());
  const [user, setUser] = useState(getLocalStorage("user"));

  // Function to log in the user
  const login = (userData) => {
    setLocalStorage("user", userData);
    setUser(userData);
  };

  // Function to log out the user
  const logout = () => {
    // Perform any logout logic here (e.g., clear tokens, cookies, or other data)
    setUser(null);
  };

  // Function to check if the user is logged in
  const isLoggedIn = () => {
    return !!user;
  };

  // Pass the values to the context provider
  const authContextValue = {
    user,
    login,
    logout,
    isLoggedIn,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

const setLocalStorage=(key, value)=>{
  if (typeof(window) === undefined) return;
  localStorage.setItem(key, JSON.stringify(value));
}

const getLocalStorage=(key)=>{
  if (typeof(window) === undefined) return null;
  return localStorage.getItem(key);
}


export default AuthProvider;
