import {
    Card,
    Text,
    Flex,
} from "@tremor/react";
import { apiPost } from "../../data/apidata";

  
const SenseController=()=>{

    return (
        <div>
            <RoomController room={"Bedroom 1"} code={"id_Nzg6RTM6NkQ6REY6RTE6QjA"} status={""} />
            <RoomController room={"Bedroom 2"} code={"id_Nzg6RTM6NkQ6REU6MzQ6MUM"} status={""} />
        </div>
    );
}

const RoomController=({room, code, status})=>{

    function updateFirebase(status, roomID){
    //  id_Nzg6RTM6NkQ6REU6MzQ6MUM
    //  id_Nzg6RTM6NkQ6REY6RTE6QjA

        apiPost("/sense/status/setstatus",
            {
                "userID" : "adc",
                "roomRef" : roomID,
                "value" : status
            },
            (d)=>{console.log(d)},
            (e)=>{console.log(e)}
        );

    }    

    return (
        <Card className="max-w-xl">
            <Text>{room}</Text>
            <Flex justifyContent="evenly">
                <button onClick={()=>updateFirebase("fall", code)}>Fall</button>
                <button onClick={()=>updateFirebase("manOnGround", code)}>On floor</button>
                <button onClick={()=>updateFirebase("bathroom", code)}>Bathroom</button>
                <button onClick={()=>updateFirebase("chair", code)}>Chair</button>
                <button onClick={()=>updateFirebase("room", code)}>Room</button>
            </Flex>
        </Card>
    );
}


export default SenseController;