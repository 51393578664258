
import { useEffect, useState } from "react";
import { Grid} from "@tremor/react";
import LiveDashboardToday from "../components/charts/LiveDashboardToday";
import LiveDashboardLiveCalls from "../components/charts/LiveDashboardLiveCalls";
import SenseLive from "../components/charts/SenseLive";

export default function Live() {
  
  const [value, setValue] = useState({
    from: new Date(2023, 1, 1),
    to: new Date(),
  });

  const [masterCompletedCalls, setMasterCompletedCalls] = useState({});
  const [completedCalls, setCompletedCalls] = useState([]);
/*  
  useEffect(()=>{ setMasterCompletedCalls(demoCalls) }, []);

  useEffect(()=>{
    const tmpArr = [];
    Object.keys(masterCompletedCalls).map((k)=>{
      tmpArr.push(masterCompletedCalls[k]);
    });
    setCompletedCalls(tmpArr);
  }, [masterCompletedCalls]);
*/

  return (
    <main className="px-12 py-12">
      {/*<div>Filters</div>*/}

      <Grid numItems={1} numItemsSm={2} numItemsLg={3} className="mt-6 gap-6">
        <LiveDashboardToday data={completedCalls} />
        <LiveDashboardLiveCalls sitesList={["arquellacare"]} />
        <SenseLive sitesList={["vayyarmonitor"]} data={performance} />
      </Grid>

    </main>
  );
}

