
import { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import DashboardCard from "../components/Card";
import { Grid,  Tab,  TabList,  TabGroup,  TabPanel,  TabPanels, DateRangePicker, DateRangePickerValue } from "@tremor/react";
import ChartCard from "../components/ChartCart";
import TableDataCard from "../components/TableDataCard";
import BarChartCard from "../components/BarChart";
import LineChartCard from "../components/LineChartCard";
import TotalCallsPerDay from "../components/charts/TotalCallsByDay";
import EmergencyVNon from "../components/charts/EmergencyVNon";
import AverageResponseTime from "../components/charts/AverageResponseTime";
import AverageResponseTimeByZone from "../components/charts/AverageResponseTimeByZone";
import CareDeliveredByType from "../components/charts/CareDeliveredByType";
import CallsByType from "../components/charts/CallsByType";
import CallsByResident from "../components/charts/CallsByResident";
import CallJourneyList from "../components/charts/CallJourneyList";
import Residents from "./Residents";
import Nourish from "./integrations/Nourish";


export default function Settings() {
  

  return (
    <main className="px-12 py-12">

      <TabGroup className="mt-6">
        <TabList>
          <Tab>Nourish</Tab>
          <Tab>MiiCare</Tab>
          <Tab>PCS</Tab>
          <Tab>Carevision</Tab>
        </TabList>
        <TabPanels>
          <TabPanel><Nourish /></TabPanel>

          <TabPanel>
            <div className="mt-6">
              Carer
            </div>
          </TabPanel>
          
          <TabPanel>
            <div className="mt-6">
              <Residents />
            </div>
          </TabPanel>

          <TabPanel>

          </TabPanel>
        </TabPanels>
      </TabGroup>
    </main>
  );
}


