
import { useEffect, useState } from "react";
import { Grid} from "@tremor/react";
import ResidentCard from "../components/ResidentCard";
import { apiPost } from "../data/apidata";
import Resident from "./Residents/Resident";
import { AQModal } from "../components/Modal/Modal";

export default function Residents({siteID}) {

  const [residentList, setResidentList] = useState([]);
  const [errors, setErrors] = useState("");
  const [openResident, setOpenResident] = useState(-2); // -2 = no screen -1 = new resident - anything higher is the resident id
  console.log("residentSiteID", siteID);
  function getResidents(){
    closeResident();

    apiPost(
      "/residents/list", 
      {
        "userID": "your arquella email address",
        "siteID": `${siteID}`
      },
      (d)=>setResidentList(d),
      (e)=>setErrors(e.toString()),
    )

  }

  function newResident(){
    setOpenResident(-1);
  }

  function closeResident(){
    setOpenResident(-2);
  }

  useEffect(()=>{
    if ((siteID===null)||(siteID==="")) return;
    getResidents();
  }, [siteID]);
  console.log({residentList});
  return (
    <main className="px-12 py-12">
      {(openResident>-2) && <AQModal show={true} onClose={closeResident}><Resident siteID={siteID} residentID={openResident} onUpdated={getResidents} /></AQModal>}
      <button onClick={()=>newResident()}>Add Resident</button>
      <Grid numItemsLg={1} className="mt-6 gap-6">
        {residentList.map((item) =><ResidentCard item={item} setActiveResident={setOpenResident} /> )}
      </Grid>
    </main>
  );
}