import {
  Card,
  Text,
  BadgeDelta,
  Flex,
  Metric,
  ProgressBar,
  DeltaBar
} from "@tremor/react";
import { secondsToHHMMSS } from "../functions/date_time";


export default function DashboardCard({item}) {

  if (item.title==="Emergency") return <EmergencyCard item={item} />;
  if (item.title==="Attendance") return <AttendanceCard item={item} />;
  
  return <EmergencyCard item={item} />;

    return(

        <Card key={item.title}>
                  <Flex alignItems="start"> 
                    <div className="truncate">
                      <Text>{item.title}</Text>
                      <Metric className="truncate">{item.metric}</Metric>
                    </div>
                    <BadgeDelta deltaType={item.deltaType}>{item.delta}</BadgeDelta>
                  </Flex>
                  <Flex className="mt-4 space-x-2">
                    <Text className="truncate">{`${item.progress} (${item.metric})`}</Text>
                    <Text>{item.target}</Text>
                  </Flex>
                  <DeltaBar value={(0-45)} isIncreasePositive={false} className="mt-3" />
                  {/* <ProgressBar value={item.progress} className="mt-2" /> */}
                </Card>

    );
}

const EmergencyCard=({item})=>{

  return(

    <Card key={item.title}>
              <Flex alignItems="start"> 
                <div className="truncate">
                  <Text>{item.title}</Text>
                  <Metric className="truncate">{item.metric}</Metric>
                </div>
         {//       <BadgeDelta deltaType={item.deltaType}>{item.delta}</BadgeDelta>
}
         </Flex>
              <Flex className="mt-4 space-x-2">
                <Text className="truncate">{`${secondsToHHMMSS(item.progress)}`}</Text>
                <Text>{secondToMin(item.target)}</Text>
              </Flex>
              <DeltaBar value={(item.progress-item.target)} isIncreasePositive={false} className="mt-3" />
              {/* <ProgressBar value={item.progress} className="mt-2" /> */}
            </Card>

  );
}


const AttendanceCard=({item})=>{
  return(

    <Card key={item.title}>
              <Flex alignItems="start"> 
                <div className="truncate">
                  <Text>{item.title}</Text>
                  <Metric className="truncate">{item.metric}</Metric>
                </div>
                <BadgeDelta deltaType={item.deltaType}>{item.delta}</BadgeDelta>
              </Flex>
              <Flex className="mt-4 space-x-2">
                <Text className="truncate">{`${secondToMin(item.progress)}`}</Text>
                <Text>{secondToMin(item.target)}</Text>
              </Flex>
              <DeltaBar value={(item.progress-item.target)} isIncreasePositive={true} className="mt-3" />
              {/* <ProgressBar value={item.progress} className="mt-2" /> */}
            </Card>

  );
}

function secondToMin(secs){
  var date = new Date(1970,0,1);
  date.setSeconds(secs);
  return date.toTimeString().replace(/.*(\d{2}:\d{2}).*/, "$1");
}