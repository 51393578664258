
import { useEffect, useState } from "react";
import { Grid} from "@tremor/react";
import SenseLive from "../components/charts/SenseLive";
import DavyMonitor from "../components/charts/DavyMonitor";

export default function VayyarMonitor() {

  return (
    <main className="px-12 py-12">
      {/*<div>Filters</div>*/}

      <Grid numItems={1} numItemsSm={2} numItemsLg={3} className="mt-6 gap-6">
        <DavyMonitor sitesList={["vayyarmonitor"]} />
      </Grid>

    </main>
  );
}

