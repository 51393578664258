
import { useState } from "react";
import { Card, Title, Text, Grid, Col } from "@tremor/react";
import NourishSite from "./nourish/NourishSite";


export default function Nourish() {
  
  const [selectedSite, setSelectedSite] = useState(null);
  const siteDetails =  [
    {
      siteID: "93",
      displayName: "Arquella Care"
    },
    {
      siteID: "65",
      displayName: "Andrew Development"
    }
    /*,
    {
      siteID: "3",
      displayName: "Dummy Site"
    },
    {
      siteID: "4",
      displayName: "Testing Care"
    }
    */
  ]

  function setSelectedSiteFromID({id}){
    const selection = siteDetails.filter(s=>(s.siteID===id));

    if (selection.length>0) {
      setSelectedSite(selection[0]);
    }
    else {
      setSelectedSite(null);
    }
  }

  return (
    <main className="px-12 py-12">

<main>
      <Title>Nourish Site Configurations</Title>
      <Text></Text>

      <Grid numItemsLg={6} className="gap-6 mt-6">
        
      <Col numColSpanLg={1}>
          <div className="space-y-6">
            {siteDetails.map(d=><Card><SiteCard siteDetails={d} onClickRun={setSelectedSiteFromID} /> </Card>)}
          </div>
        </Col>

        <Col numColSpanLg={5}>
          <Card className="h-full">
            <div>{selectedSite===null?"Null":selectedSite.displayName}</div>
            {(selectedSite!==null)&&<SelectedSiteCard siteDetails={selectedSite} />}
          </Card>
        </Col>
        
      </Grid>
    </main>
      
    </main>
  );
}


const SelectedSiteCard=({siteDetails})=>{
  console.log("ssssiite", siteDetails);
  return <NourishSite siteID={siteDetails.siteID} />;
};


const SiteCard=({siteDetails, onClickRun})=>{
  
  const id = siteDetails.siteID;

  return(
    <div 
      className="h-24"
      onClick={()=>onClickRun({id})}
      >
      {siteDetails.displayName}
    </div>
  )
}
