import { InformationCircleIcon } from "@heroicons/react/solid";
import { apiPost } from "../data/apidata";
import { useState, useEffect } from "react";
import {
  Card,
  Title,
  BadgeDelta,
  Flex,
  Icon,
  MultiSelect,
  MultiSelectItem,
  Select,
  SelectItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "@tremor/react";

export default function TableDataCard(){
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedNames, setSelectedNames] = useState([]);


  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  
  useEffect(()=>{ apiPost("/dashboard/chart/allcallslist", {}, setData, setError); },[]);
  
  const deltaTypes = {
    //average: "unchanged",
    overkpi: "moderateDecrease",
    withinkpi: "moderateIncrease",  
  };
  
  const isSalesPersonSelected = (salesPerson) =>
    (salesPerson.status === selectedStatus || selectedStatus === "all") &&
    (selectedNames.includes(salesPerson.resident) || selectedNames.length === 0);
  

    console.log("dqqqqq", data);
  const records = 
  [
    {
      resident: "Cephas Chapman",
      call: "Call",
      date: "01 February 2023",
      start: "10:28",
      end: "10:53",
      status: "overkpi",
    },
    {
      resident: "David Chapman",
      call: "Call",
      date: "01 February 2023",
      start: "10:28",
      end: "10:53",
      status: "withinkpi",
    },
    {
      resident: "Thomas Hand",
      call: "Call",
      date: "01 February 2023",
      start: "10:28",
      end: "10:53",
      status: "overkpi",
    },
    {
      resident: "Constance Chapman",
      call: "Call",
      date: "01 February 2023",
      start: "10:28",
      end: "10:53",
      status: "withinkpi",
    },
    {
      resident: "Beatrice Hand",
      call: "Call",
      date: "01 February 2023",
      start: "10:28",
      end: "10:53",
      status: "withinkpi",
    },
  ];



    if (data.length===0) return<></>;

    return (
          <Card>
                <>
                  <div>
                    <Flex className="space-x-0.5" justifyContent="start" alignItems="center">
                      <Title> Call Data </Title>
                      <Icon
                        icon={InformationCircleIcon}
                        variant="simple"
                        tooltip="Shows sales performance per employee"
                      />
                    </Flex>
                  </div>
                  <div className="flex space-x-2">
                    <MultiSelect
                      className="max-w-full sm:max-w-xs"
                      onValueChange={setSelectedNames}
                      placeholder="Select Resident..."
                    >
                      {records.map((item) => (
                        <MultiSelectItem key={item.resident} value={item.resident}>
                          {item.resident}
                        </MultiSelectItem>
                      ))}
                    </MultiSelect>
                    <Select
                      className="max-w-full sm:max-w-xs"
                      defaultValue="all"
                      onValueChange={setSelectedStatus}
                    >
                      <SelectItem value="all">All Performances</SelectItem>
                      <SelectItem value="withinkpi">Within KPI</SelectItem>
                      <SelectItem value="overkpi">Over KPI</SelectItem>
                    </Select>
                  </div>
                  <Table className="mt-6">
                    <TableHead>
                      <TableRow>
                        {console.log(Object.keys(records[0]))}
                        {
                          Object.keys(records[0]).map((r)=><TableHeaderCell>{r}</TableHeaderCell>)

                        }
                        
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {records
                        .filter((item) => isSalesPersonSelected(item))
                        .map((item) => (
                          <TableRow key={item.resident}>
                            <TableCell>{item.resident}</TableCell>
                            <TableCell className="text-right">{item.call}</TableCell>
                            <TableCell className="text-right">{item.date}</TableCell>
                            <TableCell className="text-right">{item.start}</TableCell>
                            <TableCell className="text-right">{item.end}</TableCell>
                            <TableCell className="text-right">{item.region}</TableCell>
                            <TableCell className="text-right">
                              <BadgeDelta deltaType={deltaTypes[item.status]} size="xs">
                                {item.status}
                              </BadgeDelta>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </>
              </Card>
    );
}