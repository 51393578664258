
import { useEffect, useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import DashboardCard from "../../../components/Card";
import { Tab,  TabList,  TabGroup,  TabPanel,  TabPanels, DateRangePicker, DateRangePickerValue } from "@tremor/react";
import ChartCard from "../../../components/ChartCart";
import TableDataCard from "../../../components/TableDataCard";
import BarChartCard from "../../../components/BarChart";
import LineChartCard from "../../../components/LineChartCard";
import TotalCallsPerDay from "../../../components/charts/TotalCallsByDay";
import EmergencyVNon from "../../../components/charts/EmergencyVNon";
import AverageResponseTime from "../../../components/charts/AverageResponseTime";
import AverageResponseTimeByZone from "../../../components/charts/AverageResponseTimeByZone";
import CareDeliveredByType from "../../../components/charts/CareDeliveredByType";
import CallsByType from "../../../components/charts/CallsByType";
import CallsByResident from "../../../components/charts/CallsByResident";
import CallJourneyList from "../../../components/charts/CallJourneyList";
import Residents from "../../Residents";
import { Card, Title, Text, Grid, Col } from "@tremor/react";
import { apiPost } from "../../../data/apidata";
import { Divider } from "@tremor/react";
import { TextInput } from "@tremor/react";

export default function NourishSite({siteID}) {
  console.log("nourish site", siteID);
  const [site, setSite] = useState(null);

  useEffect(()=>{
    if ((siteID===null)||(siteID==="")) return;
    getSiteData();
  }, siteID);

  console.log("ssssss", site);

  function getSiteData(){
    apiPost(
      "/integrations/nourish/site_config",
      {
        userID: "adc",
        siteID: siteID
      },
      (d)=>{ try { setSite(d[0]) } catch(e) { setSite(null) } },
      (e)=>{ console.log(e) }
    );
  }

  function updateCallBack(){
    getSiteData(); 
  }

  if ((site===null)||(Object.keys(site).length===0)) return<></>;

  
  return (
    <main className="px-12 py-12">

      <main>
        <Title>{site.siteName}</Title>
        <Text></Text>

        <SiteAPI siteID={siteID} siteDetails={site} updatedCallBack={updateCallBack} userID={"adc"} />
        <Divider />

        <Residents siteID={siteID} />
      
      </main>
      
    </main>
  );
}


const SiteAPI=({siteID, siteDetails, updatedCallBack, userID})=> {
  
  console.log("siteapi", siteDetails);

  const [apiKey, setAPIKey] = useState("");
  const [error, setError] = useState("");

  function getKey(){
    console.log("getting key");
    apiPost(
      "/tools/generate_api_key",
      {
        "userID": userID
      }, 
      (d)=>setAPIKey(d), 
      (e)=>setError(e.toString())
    );
  }

  useEffect(()=>{
    //update api key
    if (apiKey==="") return;
    console.log("updating the site key:: " + apiKey);
    apiPost(
      "/integrations/nourish/update_site_key", 
      { 
        "userID": userID,
        "siteKey": apiKey,
        "siteID": `${siteID}`
      }, 
      (d)=>updatedCallBack(), 
      (e)=>setError(e.toString())
    );

  }, [apiKey]);
  
  return(
    <>
      <div className="flex">
        <div>Site name: </div>
        <div>{siteDetails.siteName}</div>
      </div>
      <div className="flex">
        <div>API Key: </div>
        <div>{siteDetails.nourishKey}</div>
      </div>
      <button onClick={()=>getKey()} >Generate Site Key</button>    
      
    </>
  )

}
