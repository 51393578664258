
import { useEffect, useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import DashboardCard from "../components/Card";
import { Grid,  Tab,  TabList,  TabGroup,  TabPanel,  TabPanels, DateRangePicker, DateRangePickerValue } from "@tremor/react";
import { MultiSelect, MultiSelectItem, Flex } from "@tremor/react";
import ChartCard from "../components/ChartCart";
import TableDataCard from "../components/TableDataCard";
import BarChartCard from "../components/BarChart";
import LineChartCard from "../components/LineChartCard";
import TotalCallsPerDay from "../components/charts/TotalCallsByDay";
import EmergencyVNon from "../components/charts/EmergencyVNon";
import AverageResponseTime from "../components/charts/AverageResponseTime";
import AverageResponseTimeByZone from "../components/charts/AverageResponseTimeByZone";
import CareDeliveredByType from "../components/charts/CareDeliveredByType";
import CallsByType from "../components/charts/CallsByType";
import CallsByResident from "../components/charts/CallsByResident";
import CallJourneyList from "../components/charts/CallJourneyList";
import { apiPost } from "../data/apidata";
import { groupBy } from "../functions/objects_arrays";
import { displayDateFromUTC } from "../functions/date_time";



export default function Place() {
  
  const [value, setValue] = useState({
    from: new Date(),
    to: new Date(),
  });
  
  const [filterData, setFilterData] = useState([]);
  const [zonesList, setZonesList] = useState([]);
  const [zoneSelections, setZoneSelections] = useState([]);
  const [roomsList, setRoomsList] = useState([]);
  const [roomSelections, setRoomSelections] = useState([]);
  const [lastRefresh, setLastRefresh] = useState("");
  

  function getLastRefreshDate(){
    
    apiPost(
      "/dashboard/settings/getlastrefresh",
      {
        userID: "",
        siteID: 93
      },
      (d)=>{
        console.log("lffff", d);
          try { setLastRefresh(displayDateFromUTC(d[0].lastRefresh)); }
          catch(e){ setLastRefresh(""); }
        console.log("api callback", d)
      },
      (e)=>{console.log(e)}
    )

    setTimeout(()=>getLastRefreshDate(),60000);
  }

  useEffect(()=>{
    getLastRefreshDate();
  },[])

  function processFilterData(d){
    try { setFilterData(JSON.parse(d[0].FilterData)); }
      catch(e){ setFilterData([]); }
  }
  
  useEffect(()=>{
    const zns = groupBy(filterData, "Zone");
    const list = Object.keys(zns);
    setZonesList(list);
    setZoneSelections(list);
  },[filterData]);

  useEffect(()=>{
    let rms = filterData.filter(z=>(zoneSelections.includes(z.Zone)));
    const r = rms.map(rr=>(rr.Room));
    
    setRoomSelections(r);
    setRoomsList(r);
  
  },[zoneSelections])
  
  useEffect(()=>{

    apiPost("/dashboard/site/filters", 
      {
        userID: "",
        siteID: ""
      },
      (d)=>processFilterData(d),
      (e)=>{console.log(e);}
    )

  },[]);
  
  function refreshClick(){
    setLastRefresh("..updating");
    apiPost(
      "/datacollector/collect",
      {
        "userID": "",
        "siteID": "arquellacare",
        "runDate": "13072023"
      },
      (d)=>{

      },
      (e)=>{

      }
    )
  } 

  return (
    <main className="px-12 py-12">
      <small>Last data capture : {lastRefresh} &nbsp;<span onClick={()=>refreshClick()}>[Refresh]</span></small>
      <Grid numItemsLg={3} className="mt-6 gap-6">
        <MultiSelect placeholder="Zone" value={zoneSelections} onChange={(d)=>setZoneSelections(d)}>
          {zonesList.map((z)=><MultiSelectItem value={z} >{z}</MultiSelectItem>)}
        </MultiSelect>

        <MultiSelect placeholder="Room" value={roomSelections} onChange={(d)=>setRoomSelections(d)}>
          {roomsList.map((z)=><MultiSelectItem value={z} >{z}</MultiSelectItem>)}
        </MultiSelect>

        <DateRangePicker
          className="max-w-md mx-auto"
          value={value}
          onValueChange={setValue}
          selectPlaceholder="Selection"
        />

      </Grid>
      
      <PlaceKPIData dateSelections={value} zonesSelection={zoneSelections} roomsSelections={roomSelections}/>

      <Grid numItemsLg={3} className="mt-6 gap-6">
        
              
      {/*
        <TotalCallsPerDay siteID={93} />
        <ChartCard data={performance} />
        <CallsByType />
        
        <EmergencyVNon />
        <AverageResponseTime />
        <AverageResponseTimeByZone />
        <CareDeliveredByType />
      */}

      </Grid>

    </main>
  );
}

function zeroIfNullEmpty(val){
  return ((val===null) || (val==="")) ? 0 : val;
}


const PlaceKPIData=({dateSelections, zonesSelections, roomsSelections})=>{
  
  const [data, setData] = useState([]);

  function getValueFromObject(dataObject, key, value){
    try { return dataObject[key][0][value]; }
      catch(e){ return ""; }
  }

  console.log("dateSelections", dateSelections);
  useEffect(()=>{
    console.log("KPPII Data");
    apiPost(
      "/dashboard/place/kpicards",
      {
        userID : "",
        siteID : 93,
        startDate: dateSelections.from,
        endDate: dateSelections.to,
        zonesSelections: zonesSelections,
        roomsSelections: roomsSelections
      },
      (d)=>{
        
        const dta = groupBy(d, "callType");
        const cardTitles = ["Emergency", "Sense", "Call", "Attendance"];
        
        const dataArr = cardTitles.map(d=>{
          return {
              title: d,
              metric: zeroIfNullEmpty(getValueFromObject(dta, d, "callCount")),
              progress: zeroIfNullEmpty(getValueFromObject(dta, d, "average_duration")),
              target: 600,
              delta: "-9",
              deltaType: "increase",
          }
        });
        
        setData(dataArr);
        
      },
      (e)=>{console.log(e)}
    );

  },[dateSelections, zonesSelections, roomsSelections]);
  
  return(
      <Grid numItemsLg={4} className="mt-6 gap-6">
        {data.map((item)=><DashboardCard item={item} />)}
      </Grid>
  );
}


/*
              <ChartCard data={performance} />
              <BarChartCard />
              <LineChartCard />

*/
/*
moderateDecrease
increase
moderateIncrease
decrease
*/


export const performance = [
  {
    date: "2023-05-01",
    Sales: 900.73,
    Profit: 173,
    Customers: 73,
  },
  {
    date: "2023-05-02",
    Sales: 1000.74,
    Profit: 174.6,
    Customers: 74,
  },
  {
    date: "2023-05-03",
    Sales: 1100.93,
    Profit: 293.1,
    Customers: 293,
  },
  {
    date: "2023-05-04",
    Sales: 1200.9,
    Profit: 290.2,
    Customers: 29,
  },
];