import { Card, Title, LineChart } from "@tremor/react";
import { Grid, Accordion, AccordionHeader, AccordionBody, AccordionList } from "@tremor/react";
import { apiPost } from "../../data/apidata";
import { useState, useEffect } from "react";
import {groupBy}  from '../../functions/objects_arrays';
import { GetCallTypeImage } from "../GetImage";
import { DataTable } from '../DataTable';
import { getOnValue } from "../../data/firebase";

export default function LiveDashboardToday(){

  const [data, setData] = useState([]);
  const callsList = ["Emergency", "Sense", "Assistance", "Call", "Attendance", "Care Call"]
  const [callData, setCallData] = useState({});
  const [error, setError] = useState({});

  useEffect(()=>{
    console.log("setting call data");
    setCallData(groupBy(data, "callType"));

  }, [data]);
  
  function doubleFigure(val){
    if (val<10) return "0" + val;
    return val;
  }

  function getDatePathFromDate(d){
    return doubleFigure(d.getDate()) + doubleFigure(d.getMonth() + 1) + doubleFigure(d.getFullYear());
  }

  useEffect(()=>{
    getOnValue("arquellacare_site/calls/" + getDatePathFromDate(new Date()), 
    (d)=>{
      try { setData(Object.values(d)); }
        catch(e){ 
          console.log("Error setting records");
          setData([]); 
        }
    }, setError);
  },[])


  function getCallData(key){
    let rtn = callData[key];
    if (rtn===undefined) rtn = [];
    return rtn;
  }

    console.log("callData", callData);

  return(
  <Card 
  //className="w-2xl"
  >
    <Title className="upper_case">Today's calls</Title>
    <br />
    <AccordionList className="max-w-md mx-auto">

    <Accordion className="bg_all">
      <AccordionHeader>
        <DashboardHeader value="All Calls" data={[]} />
      </AccordionHeader>
      <AccordionBody>
        <ShowDataTable data={[]} />
      </AccordionBody>
    </Accordion>

    {
      callsList.map((c)=>{

        const d = getCallData(c);
        return(
          <Accordion className={"bg_" + cleanCallTypeText(c)}>
          <AccordionHeader>
              <DashboardHeader value={c} data={d} />
          </AccordionHeader>
          <AccordionBody>
            <ShowDataTable data={d} />
          </AccordionBody>
        </Accordion>
        );
      })
    }
    
  </AccordionList>
    
    
  </Card>
)};

function dateDiffDuration(start, end){
  return new Date(end) - new Date(start);
}

function calculateAverage(count, value){
  const seconds = Math.floor(value/count);
  const result = new Date(seconds).toISOString().slice(11, 19);
  return result;
}
function getAverageOfArray(arr){
  console.log("lllst", arr);
  let tot = 0;
  let count = 0;
  arr.forEach((e)=>{ 
    if (!isNaN(e)) {
      tot += e;
      count++;
    } 
  })
  return calculateAverage(count, tot);
}

function getAverageDuration(data){
  if ((data===null)||(data.length===0)) return "00:00:00";
  const durationList = data.map(d=>(dateDiffDuration(d.start, d.end_date_time_utc)));
  return getAverageOfArray(durationList);
  console.log("durationList", durationList);
}


function DashboardHeader({data, value}){

console.log("dashHeaderData", data);

return (
  <div className="dashboard_calls_header">
    <GetCallTypeImage imgString={value} />
    <div>{value}</div>
    <div>{getAverageDuration(data)}</div>
    <div>{data.length}</div>
  </div>
);

}

function cleanCallTypeText(ccString){
  ccString = ccString.toLowerCase();
  ccString = ccString.replace(" ", "");
  return ccString;
}


function ShowDataTable({data}){
  if (data===undefined) return (<>No data</>);
  return(
    <DataTable data={data} />
  );
}