import { initializeApp } from 'firebase/app';
import { getDatabase, onValue, ref, get} from "firebase/database";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
    authDomain: "arquella-cc76e.firebaseapp.com",
    databaseURL: "https://arquella-cc76e.firebaseio.com",
    projectId: "arquella-cc76e",
    storageBucket: "arquella-cc76e.appspot.com",
    messagingSenderId: "673271376643",
    appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
    measurementId: "G-10LF2R4MV2"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const login=(userName, password)=>signInWithEmailAndPassword(auth, userName, password);

export function getOnValue(strDBRef, returnOnValue, returnOnError){
    console.log("Listening to ref: " + strDBRef);
    const database = getDatabase(app);
    const dbRef = ref(database, strDBRef);
    onValue(dbRef, 
        (snapshot)=>{ returnOnValue(snapshot.val()); },
        (error)=>{ returnOnError(error); }
    );
}


export function getOnceValue(strDBRef, returnOnValue, returnOnError){
    const database = getDatabase(app);
    const dbRef = ref(database, strDBRef);

    get(dbRef)
        .then((snapshot)=>{ returnOnValue(snapshot.val()); })
        .catch((error)=>{ returnOnError(error); });
}