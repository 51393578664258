import { Card, Title, DonutChart } from "@tremor/react";
import { apiPost } from "../../data/apidata";
import { useState, useEffect } from "react";


export default function Emergencyvnon(){

    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [error, setError] = useState("");
    
    useEffect(()=>{ apiPost("dashboard/chart/emergencyvnon", {
      "userID" : "",
      "siteID" : ""   
    }, setData, setError); },[]);

    useEffect(()=>{

        const d = [];

        if (data.length>0){
            const t = data[0];
            d.push({
                name: "Emergency",
                calls: t["Emergency"],
              });
            d.push({
                name: "Non emergency",
                calls: t["Non Emergency"],
            });
        }

        setChartData(d);

    },[data]);


  return(
  <Card className="max-w-lg">
    <Title>Non emergency v emergency calls</Title>
    <DonutChart
      className="mt-6"
      data={chartData}
      category="calls"
      index="name"
//      valueFormatter={valueFormatter}
      colors={["red", "teal"]}
    />
    <div>Non Emergency calls include - Call, Assistance, Accessory</div>
  </Card>
)};