import React, { useEffect, useState } from 'react';
import { select, drag, selectAll } from 'd3';
import { lCase } from '../../functions/string';
import './FloorPlan.css';

const FloorPlan=({staffMovementCallBack, carer, callsList})=>{
  //let lastRoom = "";
  let mousePointerRoom = "";

  const [lastRoom, setLastRoom] = useState("");
  //  const [mousePointerRoom, setMousePointerRoom] = useState("");

  const [carerPosition, setCarerPosition] = useState({
    x: 450,
    y: 300
  })

  function updateCarerPos(x, y){
    setCarerPosition({x:x, y:y});
  }

  function handleDragStart(event) {
    event.dataTransfer.setData('text/plain', event.target.id);
  }

  function handleDragOver(event) {
    event.preventDefault();
  }

  function handleDrop(event) {
    event.preventDefault();
    const personId = event.dataTransfer.getData('text/plain');
    const svg = select('.floor-plan-svg');
    
    const { offsetX, offsetY } = event.nativeEvent;
    svg
      .append('circle')
      .attr('cx', offsetX)
      .attr('cy', offsetY)
      .attr('r', 10)
      .attr('fill', 'blue')
      .attr('class', 'person');
  }

  function renderFloorPlan() {

    const svg = select('.floor-plan-svg');
    svg.selectAll('*').remove();
    
    
    const firstRowY = 5;
    
    const room1 = drawRoomObject(svg, 5, firstRowY, "001", "Bedroom 1");
    const room2 = drawRoomObject(svg, 250, firstRowY, "002", "Bedroom 2");
    const room3 = drawRoomObject(svg, 495, firstRowY, "003", "Bedroom 3");
    
    const secondRowY = 275;
    const room4 = drawRoomObject(svg, 5, secondRowY, "004", "Bedroom 4");
    const room5 = drawRoomObject(svg, 250, secondRowY, "005", "Bedroom 5");
    const room6 = drawRoomObject(svg, 495, secondRowY, "006", "Bedroom 6");

    const staff1 = drawStaff(svg, carerPosition.x, carerPosition.y, 10);
      staff1.call(dragStaffHandler);
      
  }


  
  function dragEndCheckForRoom(){
    if (mousePointerRoom!=="") {
      setLastRoom(mousePointerRoom);
      //lastRoom = mousePointerRoom;
    }
    staffMovementCallBack(mousePointerRoom, lastRoom);
  }

  useEffect(()=>{ renderFloorPlan(); },[carer, callsList])


  const dragStaffHandler = drag()
  .on("drag", function(e) {
    const newX = e.x;
    const newY = e.y;

    select(this)
      .attr("cx", newX)
      .attr("cy", newY)
      .attr('pointer-events', 'none');
    
    updateCarerPos((newX), newY);
    
    select("#carer_text")
      .attr("x", (newX +38))
      .attr("y", newY)
      .attr('pointer-events', 'none');

  })
  .on("end", function(e) { 
    
    dragEndCheckForRoom(); 
    select(this).attr('pointer-events', null);

  });


const drawRoomObject=(svg, x, y, id, roomText)=>{
    const g = svg.append("g")
      drawRoom(g, x, y, id);
      drawText(g, (x + (configFloorPlan.roomWidth/2)) , (y + (configFloorPlan.roomHeight/2)), roomText);
}

const drawRoom=(obj, x, y, id)=>{
  obj.append("rect")
    .attr("x", x)
    .attr("y", y)
    .attr("width", configFloorPlan.roomWidth)
    .attr("height", configFloorPlan.roomHeight)
    .attr("fill", getRoomColour(id))
    .attr("id", id)
    .on('mouseenter', d => { mousePointerRoom = id; })
    .on('mouseleave', d => { mousePointerRoom = ""; })
    ;
            //.attr('class', 'floorplan_room');
}


function getRoomColour(roomCode){
  try {
    const callT = lCase(callsList.filter(c=>(c.unitId===roomCode))[0].callType);
    
    if (callT==="emergency") return "red";
    if (callT==="call") return "rgb(245, 129, 78)";
    if (callT==="sense") return "#914397";
    if (callT==="attendance") return "rgb(148, 202, 102)";
    if (callT==="accessory") return "#914397";
    if (callT==="assistance") return "#F8DA3C";
    if (callT==="carecall") return "rgb(225, 21, 131)";
  }
  catch(e){ console.log(e); }
  
  return "pink";
}

const drawText=(obj, x, y, text, id)=>{
  let txt = obj.append("text")
      .attr("x", x)
      .attr("y", y)
      .attr("dy", ".35em")
      .attr("id", id)
      .attr("text-anchor", "middle")
      .attr('pointer-events', 'none')
      .text(text);

}

const drawStaff=(svg, x, y, radius)=>{
  
    let staff = svg.append('circle')
                  .attr('cx', x)
                  .attr('cy', y)
                  .attr('r', radius)
                  .attr('fill', 'red')
                  .attr('class', 'floorplan_staff')
                //.attr('draggable', true)
                //.attr('id', (d, i) => `person-${i}`)
                //.on('dragstart', this.handleDragStart)
                ;
            drawText(svg, (x+38), y, carer, "carer_text");
          
  return staff;
}

const drawCircle=(svg, x, y, radius)=>{
  return svg.append('circle')
            .attr('cx', x)
            .attr('cy', y)
            .attr('r', radius)
            .attr('fill', 'red')
            .attr('class', 'floorplan_room_corner')
            //.attr('draggable', true)
            //.attr('id', (d, i) => `person-${i}`)
            //.on('dragstart', this.handleDragStart)
            ;
}


const rectangle=(svg, x, y, width, height, fill)=>{
  return svg.append("rect")
            .attr("x", x)
            .attr("y", y)
            .attr("width", width)
            .attr("height", height)
            .attr("fill", fill)
            .attr('class', 'floorplan_room');
}

const configFloorPlan = {
  roomSelectionRadius: 5,
  roomHeight: 200,
  roomWidth: 150
}

  
  return (
    <div className="floor-plan-container">
      <svg
        width="500px"
        className="floor-plan-svg"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      ></svg>
    </div>
  );
  
}

export default FloorPlan;



