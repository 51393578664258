export function secondsToHHMMSS(varSeconds){
    console.log("secondsToHHMMSS");
    console.log(varSeconds);
    return(varSeconds);
    return new Date(varSeconds * 1000).toISOString().substring(11, 16);
        //If SECONDS<3600 and if you want to show only MM:SS then use below code:
    //new Date(SECONDS * 1000).toISOString().substring(14, 19)
}

export function displayDateFromUTC(utcDate){
    utcDate = utcDate.replace("T", " ");
    return utcDate = utcDate.split(".")[0];
}